import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassLessonStudentRepository from '@/shared/http/repositories/socialProject/classlessonstudent'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import FrequencyCriteria from '@/shared/models/criteria/frequencycriteria'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { customDateFilter } from '@/shared/filters/ToCustomDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import eAbsenceJustification from '@/shared/enums/absenceJustification'
import moment from 'moment'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  data: () => ({
    emptyText: 'Nenhum registro encontrado',
    day: null,
    dateStartFormatted: null,
    dateEndFormatted: null,
    searching: false,
    isBusy: false,
    sum: {
      presents: 0,
      notPresents: 0
    },
    criteria: new FrequencyCriteria(),
    modalityLocalTraining: [],
    totalFrequencies: [],
    frequencies: [],
    localTrainings: [],
    modalities: [],
    categories: [],
    teachers: [],
    localTraining: {},
    showDrawerLocalTrainingInformation: false,
    localTrainingClasses: [],
    classLessonDetails: [],
    fields: [
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'left-header-border text-center'
      },
      {
        key: 'totalPresents',
        label: 'TOTAL PRESENTES',
        class: 'middle-header text-center'
      },
      {
        key: 'totalNotPresents',
        label: 'TOTAL AUSENTES',
        class: 'right-header-border text-center'
      }
    ],
    fieldsClasses: [
      {
        key: 'className',
        label: 'Turma',
        class: 'left-header-border text-center'
      },
      {
        key: 'totalPresent',
        label: 'Presentes',
        class: 'middle-header text-center'
      },
      {
        key: 'totalNotPresent',
        label: 'Ausentes',
        class: 'right-header-border text-center'
      }
    ],
    fieldsClassLessonDetails: [
      {
        key: 'name',
        label: 'Aluno',
        class: 'left-header-border text-center'
      },
      {
        key: 'isPresent',
        label: 'Situação',
        class: 'middle-header text-center',
        sortable: true,
        formatter: (value, key, item) => {
          if(!item) return
          if (!value) return 'Faltou'

          return 'Presente'
        }
      },
      {
        key: 'absenceJustification',
        label: 'Justificativa',
        class: 'right-header-border text-center',
        formatter: (value, key, item) => {
          if(!item || !value) return '-'

          const justification = Object.values(eAbsenceJustification).find(x => x.id == value).name
          if (justification != null) return justification
        }
      }
    ]
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
            href: this.$router.resolve({ name: 'ReportStudentList' }).href
          },
          {
            text: 'Frequência'
          }
        ]
      }
    }
  },

  filters: {
    dayFormatted(value) {
      if(!value) return undefined

      moment.locale('pt-br')

      const d = moment(value, ["DD/MM/YYYY", moment.ISO_8601])

      if (d.isValid()) {
        return d.format('DD/MM/YYYY')
      }

      return undefined
    }
  },

  created() {
    this.getAllModalityByLocalTraining()
    this.getAllTeachers()
    this.dayBefore()
  },

  methods: {
    onLoadClassLessonDetails(obj) {
      loading.push()
      ClassLessonStudentRepository.GetStudentsClassLesson(obj.classLessonId)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()
          if (res.data.data.length > 0) {
            this.classLessonDetails = res.data.data
            this.classLessonDetails[0].className = obj.className
          } else {
            this.classLessonDetails = []
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos da turma', 'ERRO')
        })
    },

    resetForm() {
      this.criteria = new FrequencyCriteria()
    },

    onGetFrequency(total) {
      this.frequencies = []
      let criteria = this.subCriteria(total)
      this.isBusy = true
      ClassLessonStudentRepository.GetFrequency(criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.frequencies = res.data.data
          this.isBusy = false
          return Promise.resolve()
        })
        .catch(() => {
          this.isBusy = false
          toast.error('Erro ao pesquisar frequência', 'ERRO')
        })
    },

    subCriteria(total) {
      let criteria = {
        localtrainingId: this.criteria.localtrainingId,
        modalityId: this.criteria.modalityId,
        categoryId: this.criteria.categoryId,
        teacherId:  this.criteria.teacherId,
        dateStart: customDateFilter(total.date)
      }
      return criteria
    },

    onGetTotal() {
      if (!this.criteria.dateStart) {
        return toast.warning('Uma data inicial é necessária', 'AVISO')
      }

      if ((this.criteria.localtrainingId ||
          this.criteria.modalityId ||
          this.criteria.categoryId ||
          this.criteria.teacherId) &&
          !this.criteria.dateEnd) {
        return toast.warning('É necessário informar data inicial e final para filtrar por local, modalidade, categoria e professor.', 'AVISO')
      }

      if(this.criteria.dateStart && this.criteria.dateEnd) {
        const start = moment(this.criteria.dateStart)
        const end = moment(this.criteria.dateEnd)
        if (start.isAfter(end)) {
          return toast.warning('Data inicial deve ser menor ou igual a data final.', 'AVISO')
        }
      }
      
      this.searching = true
      loading.push()
      ClassLessonStudentRepository.GetTotalFrequency(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.totalFrequencies = res.data.data
          this.frequencies = []
          loading.pop()
          this.searching = false
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.searching = false
          toast.error('Erro ao pesquisar frequência', 'ERRO')
        })
    },

    showLocalTrainingInformation(localTraining) {
      this.showDrawerLocalTrainingInformation = true
      this.localTraining = localTraining
    },

    onCloseDrawer() {
      this.showDrawerLocalTrainingInformation = false
      this.localTraining = {}
      this.classLessonDetails = {}
    },

    getAllModalityByLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar modalidades por local de treinamento', 'ERRO')
        })
    },

    onSelectLocalTraining() {
      let list = this.modalityLocalTraining

      if (this.criteria.localTrainingId) {
        const type = this.localTrainings.filter(localTraining => localTraining.id === this.criteria.localTrainingId)

        if (type.length > 0) {
          this.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(modalityLocal => modalityLocal.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality() {
      let list = this.modalityLocalTraining

      if (this.criteria.modalityId) {
        let type = this.modalities.filter(s => s.id === this.criteria.modalityId)

        if (type.length > 0) {
          this.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.localTrainings = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.localTrainings.filter(l => l.id === local.id).length === 0) {
          this.localTrainings.push(local)
        }
      }

      this.getAllCategories()
    },

    getAllCategories() {
      this.categories = []
      if (this.criteria.modalityId) {
        CategoryRepository.GetAllByModality(this.criteria.modalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            toast('Não foi possível carregar as categorias')
          })
      }
    },

    getAllTeachers() {
      TeacherRepository.GetAll()
        .then(response => {
          this.teachers = response.data.data
        })
        .catch(() => {
          toast('Não foi possível carregar os professores')
        })
    },

    onContextDateStart(ctx) {
      this.dateStartFormatted = ctx.selectedFormatted
    },

    onContextDateEnd(ctx) {
      this.dateEndFormatted = ctx.selectedFormatted
    },

    formatDateStart(event) {
      this.criteria.dateStart = customDateFilter(event.currentTarget.value)
    },

    formatDateEnd(event) {
      this.criteria.dateEnd = customDateFilter(event.currentTarget.value)
    },

    sumFrequencies(totalFrequencies) {
      this.sum = {
        presents: 0,
        notPresents: 0
      }
      totalFrequencies.reduce((acc, { totalPresents, totalNotPresents }) => {
        acc.presents += totalPresents
        acc.notPresents += totalNotPresents
        return acc
      }, this.sum) 
    },

    showDay(total) {
      this.day = dateFormattedFilter(total.date)
    },

    dayBefore() {
      let yesterday = moment().subtract(1, 'days')
      const dow = yesterday.isoWeekday()

      if (dow === 7) return
     
      yesterday = yesterday.toISOString()
      this.criteria.dateStart = yesterday
      this.onGetTotal(this.criteria)
    }
  }
}
