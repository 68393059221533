export default class FrequencyCriteria {
  constructor({
    localtrainingId,
    modalityId,
    categoryId,
    teacherId,
    dateStart,
    dateEnd,
  } = {}) {
    this.localtrainingId = localtrainingId
    this.modalityId = modalityId
    this.categoryId = categoryId
    this.teacherId = teacherId
    this.dateStart = dateStart
    this.dateEnd = dateEnd
  }
}